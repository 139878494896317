.us-titulo {
  padding: 50px 10px;
  font-size: 50px;
}

.us-description {
  padding: 0px 10px;
  font-size: 20px;
  text-align: justify;
}
a:visited {
    text-decoration: none;
    color: #090909;
  }

  @media (max-width: 480px) {
    .us-description {
      padding: 0px 10px;
      font-size: 20px;
      text-align: left;
    }
    
  }