.containerCon {
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: left;
  padding: 80px 80px;
}
.containerCon > div:nth-child(2) {
  align-self: flex-start;
}
.containerConL {
  margin-top: 1000px;
}
.containerConh2{
align-items: top;
}

.containerCon h2 {
  font-size: 45px;
}
.containerCon P  {
  text-align: left;
  padding: 0px 0px;
  font-size: 30px;

}

.containerConP p {
  align-items: center;
  font-size: 30px;
}

.containerCon img {
  max-width: 30px;
}

@media (max-width: 480px) {
  .containerCon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 10px;
  }

  .containerCon > div {
    flex-basis: 100%;
  }

  .containerCon h2 {
    font-size: 20px;
  }

  .containerCon p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .containerCon a {
    color: #000;
    text-decoration: none;
  }

  .containerCon img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
