:root {
    --tinto: #731D28;
    --amarillo: #FFDB3C;
    --gris: #8E9192;
    --gris-oscuro: #383839;
    --negro: #000000;
}

* {
    /* Box model */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    font-family: Ubuntu;
}

.image-container {
    display: grid;
    place-content: center;
}

.ProductView__img {
    width: 300px;
    margin-bottom: 15px;
}

.ProductView__img-carousell {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;
}

.ProductView__img-carousell-min {
    width: 80px;
    height: 96px;
}

.ProductView__info {
    width: inherit !important;
    margin-top: 15px;
    margin-bottom: 50px;
    padding-top: 15px;
}

.ProductView__info-title {
    font-size: 40px !important;
    font-weight: bold;
    font-family: Ubuntu;
    color: var(--negro);
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.ProductView__info-accordion {
    width: 90%;
}

.ProductView__info-description {
    font-size: 1.6rem;
    font-family: Lato;
    line-height: 2.6rem;
    box-sizing: border-box;
    text-align: justify;
    width: 90%;
}

.ProductView__info-specs {
    width: 50%;
    font-size: 2.6rem;
    font-weight: bold;
    font-family: Ubuntu;
    color: var(--negro);
    margin-top: 50px;
    margin-bottom: 20px;
}

.accordion {
    background: #ffffff !important;
    border: none !important;
}

.accordion-title {
    font-size: 19px !important;
    color: #000000 !important;
    font-family: Lato !important;
}

.accordion-details {
    background: #fff !important;
    box-shadow: 0 !important;    
}

.accordion-desc {
    font-size: 1.6rem !important;
    color: var(--gris-oscuro) !important;
    font-family: Lato !important;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.img-certificate{
    width: 13rem !important;
}

.telas{
    margin: 5px;
}

.accordion-colors {
    display: inline-block !important;
}

.accordion-colors span {
    display: block;
    font-family: Ubuntu;
    font-size: 1.7rem;
    margin: 5px;
}

@media screen and (min-width: 900px) {
    .ProductView {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .ProductView__info-title {
        text-align: left;
        grid-column-start: 2;
    }

    .image-container {
        width: 650px;
        height: 600px;
    }

    .ProductView__img {
        width: 420px;
        position: relative;
        bottom: 80px;
        margin-bottom: 50px;
        overflow: hidden;
    }
    
    .ProductView__img-carousell {
        padding-left: 50px;
        width: inherit;
        height: 120px;
    }

    .ProductView__img-carousell-min {
        width: 100px;
        height: 120px;
    }
    .imgIzq {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
        
    }
    .imgDer {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
    }
}

@media screen and (max-width: 480px) {
   
    .imgIzq {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
        
    }
    .imgDer {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
    }
}