.prods {
    display: grid;
    grid-template-columns: 40% 60%;

}

.img1 {
    width: 100%;
    height: 100%;
    
}
.img2 {
    width: 100%;
    height: 100%;
}