.navbar-bg {
  background: #ffffff !important;
}

.colorLetter {
  color: var(--negro) !important;
  font-weight: bold !important;
}

.nav-links {
  justify-content: space-evenly;

}