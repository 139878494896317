.img-containerTl img {
  max-width: 100%;
  padding: 0px 250px ;
  height: auto;
}

@media (max-width: 480px) {
  .img-containerTl img {
    max-width: 100%;
    padding: 0px 15px ;
    height: auto;
  }
  
}