:root {
    /* Colores */
    --tinto: #751C27;
    --amarillo: #FFDB3C;
    --gris: #777777;
    --let-amarillas: #EEB323;
}

.product-display {
    margin: 10px auto;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1;
    gap: 10px;
}

.product-image {
    display: grid;
    place-items: center;
}

.product-image img {
    width: 510px;
    height: 450px;
}

.par {
    font-family: Ubuntu;
    text-align: justify;
    padding-top: 100px;
    padding-right: 25px;
}

.title {
    font-family: Ubuntu;
    font-size: 3rem !important;
    color: #000000;
    margin-bottom: 20px !important;
}

hr {
    height: 3px !important;
    margin-left: 0%;
    margin-right: 75%;
    background-color: var(--let-amarillas);
    border: none !important;
    border-radius: 5px;
}

.subtitle {
    font-size: 2rem !important;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 10px !important;
    text-align: left;
}

.paragraph {
    font-size: 1.6rem !important;
    color: #000000;
    margin-bottom: 20px !important;
}

.btn {
   
    margin: 15px 0;
    width: 100px;
    height: 30px;
    font-size: 1.4rem !important;
    font-family: Ubuntu !important;
}


@media (max-width: 850px){
    .product-display {
        margin: 10px auto;
        padding: 15px 0;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    .title {
        grid-column: 7 / span 12;
    }
    
    .product-image {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
        width: 350px;
        margin: 0 !important;
    }

    .product-image img {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .par {
        grid-column: 7 / span 12;
        font-family: Ubuntu;
        text-align: left;
        grid-row: 2 / 3;
        z-index: 100;
        padding-top: 25px;
    }
    
    .title {
        font-size: 3rem !important;
        color: var(--tinto);
        margin-bottom: 20px !important;
        margin-top: 20px !important;
        grid-row: 1;
    }
}

@media (max-width: 700px) {
    .product-display {
        display: flex;
        flex-wrap: wrap;
    }
    
    .par {
        text-align: justify;
        padding: 0;
    }
}

@media (max-width: 480px){
   
    
    .product-image {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
        width: 100%;
        margin: 0 !important;
    }

    .product-image img {
        height: auto;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .par {
        grid-column: 7 / span 12;
        font-family: Ubuntu;
        text-align: left;
        grid-row: 2 / 3;
        z-index: 100;
        padding: 25px 10px;
    }
    
    .title {
        font-size: 3rem !important;
        color: var(--tinto);
        margin-bottom: 20px !important;
        margin-top: 20px !important;
        grid-row: 1;
    }
}