.header--container_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

.header-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}

.header-image img {
  height: 90px;
}

.header-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.header--navbar {
  display: flex;
}

.header--navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.header--navbar-list a {
  padding: 0 25px;
  text-decoration: none;
  color: #333;
  font-size: 5em;
}

a:hover {
  cursor: pointer;
}

/* For screens smaller than 576px (extra small devices) */
@media (max-width: 575.98px) {
  .header--container_info {
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .header-image {
    margin: 0;
    text-align: center;
  }

  .header-info {
    margin: 0px 0;
  }

  .header--navbar {
    flex-direction: column;
    align-items: center;
  }

  .header--navbar-list {
    padding: 0;
  }

  .header--navbar-list a {
    padding: 0px;
    font-size: 2em;
  }
}
