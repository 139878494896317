.ow-titulo {
  margin-top: 50px;
  margin-left: 30px;
  font-size: 50px;
}
.ow-titulo2 {
  margin-top: 50px;
  margin-left: 30px;
  font-size: 43px;
}
.ow-bold{
  padding: 50px 30px;
  font-size: 25px;
  font-weight: bold;
  text-align: justify;
}
.ow-bold2{
  padding: 15px 30px;
  font-size: 25px;
  font-weight: bold;
  text-align: justify;
}
.ow-text{
  padding: 0px 30px;
  font-size: 25px;
  text-align: justify;
}
.ow-text2{
  padding: 0px 30px;
  font-size: 25px;
  text-align: justify;
  margin-bottom: 200px;
}

@media (max-width: 768px) {
  .ow-titulo {
    margin-top: 50px;
    margin-left: 30px;
    font-size: 50px;
  }
  .ow-titulo2 {
    margin-top: 50px;
    margin-left: 30px;
    font-size: 43px;
  }
  .ow-bold{
    padding: 50px 30px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
  .ow-bold2{
    padding: 15px 30px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;

  }
  .ow-text{
    padding: 0px 15px;
    font-size: 20px;
    text-align: left;
  }
  .ow-text2{
    padding: 0px 15px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .ow-text3{
    padding: 0px 0px;
    font-size: 20px;
    text-align: justify;
    margin-bottom: 200px;
  }
}

