.mw-titulo1 {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 30px;
  margin-left: 0px;
  font-size: 50px;
}
.mw-titulo {
  margin-top: 50px;
  margin-left: 30px;
  font-size: 45px;
}
.mw-text{
  padding: 0px 30px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
}
.img-containerMw img {
  max-width: 100%;
  padding: 0px 30px ;
  height: auto;
}

@media (max-width: 480px) {
  .mw-titulo1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding-right: 10px;
  }

  .mw-titulo {
    font-size: 24px;
    margin-bottom: 10px;

  }

  .img-containerMw {
    margin-bottom: 10px;
  }

  .mw-text {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 10px;
    text-align: left;

  }
  
  
}