.section-footer {
  background-color: #c5c5c5;
  padding: 60px;
  color: #636469;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.container-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.container-columns > div {
  width: 30%;
  margin: 0 1rem;
  text-align: left;
}

.section-footer h2,
h3 {
  color: #636469;
  text-align: left;
}
.section-footer {
  color: #636469;
  text-align: left;
}

.section-footer a {
  color: #636469;
  text-decoration: none;
  text-align: left;
}
.section-footer h2 a {
  color: #636469;
  text-decoration: none;
  text-align: left;
}

.section-footer a:hover {
  text-decoration: underline;
  color: #636469;
}

.section-footer .copyright {
  text-align: left;
  color: #c22def;
  font-size: 80px;
}

.section-footer svg {
  font-size: 3em;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .container-columns {
    flex-direction: column;
  }

  .container-columns > div {
    width: 100%;
    margin: 0;
  }

  .section-footer {
    padding: 30px;
  }

  .section-footer .social-icons {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .section-footer h2 {
    font-size: 1.2rem;
  }

  .section-footer a {
    font-size: 1.2rem;
  }

  .section-footer .social-icons {
    display: flex;
    justify-content: center;
  }

  .section-footer svg {
    font-size: 2.5em;
  }
}
