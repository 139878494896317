:root {
    /* Colores */
    --tinto: #751C27;
    --amarillo: #FFDB3C;
    --gris: #777777;
    --let-amarillas: #EEB323;
}


.add {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image {
    width: 140px;
    height: 168px;
    /* border: 2px solid black; */
    transition: 200ms;
}

.card-img-top:hover {
    /* margin-bottom: 10px; */
    scale: 1.05;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.card {
    width: 140px;
    border: none !important;
    margin: 15px !important;
}

.card-body {
    margin-top: 10px;
    padding: 0 !important;
    text-align: center;
    font-family: Ubuntu;
    font-size: 1.8rem;
    color: #333;
}



.productos {
    display: flex;
    justify-content: left;
    text-align: center;
    padding: 10px;
    background: var(--tinto);
    color: white;
    margin-top: 5px;
    font-size: 1.6rem;
    width: 92px;
    border-radius: 8px;
}

@media screen and (min-width: 600px) {
    .add {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .card-product {
        margin: 5px;
    }

    .image {
        width: 250px;
        height: 300px;
        /* border: 2px solid black; */
        transition: 200ms;
    }

    .card {
        width: 250px;
        border: none !important;
        margin: 15px !important;
    }
}