.gw-container {
  display: grid;
  grid-template-columns: 25% 75%;
  padding: 20px 0px;
  font-size: 50px;
}

.gw-container2 {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0px 0px;
  font-size: 50px;
}

.gw-titulo {
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 140px;
  font-size: 45px;
  color: #54651b;
}

.gw-titulo2 {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 55px;
  color: #377e45;
  text-align: center;
}
.gw-titulo3 {
  margin-top: 0px;
  font-size: 40px;
  text-align: center;

  color: #54651b;
}
.gw-titulo4 {
  margin-top: 0px;
  font-size: 25x;
  text-align: center;

  color: #000000;
}
.gw-titulo5 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  color: #000000;
}
.gw-titulo6 {
  padding: 30px 100px;
  text-align: justify;
  font-size: 25px;
  color: #000000;
}
.gw-text {
  margin-top: 0px;
  margin-left: 50px;
  margin-right: 140px;
  font-size: 25px;
  text-align: justify;
}

.gw-text2 {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 140px;
  font-size: 25px;
  text-align: justify;
}
.gw-text3 {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 140px;
  font-size: 25px;
  text-align: justify;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
}

.img-container img {
  width: 20%;
  height: auto;
}

.img-container2 {
  display: flex;
  justify-content: right;
  align-items: right;
  margin-left: 50px;
  margin-top: 75px;
}

.img-container2 img {
  max-width: 50%;
  padding: 0px 0px;
  height: auto;
}

.img-container3 {
  display: flex;
  justify-content: right;
  align-items: right;
  margin-left: 10px;
  margin-right: 30px;
  margin-top: 20px;
}

.img-container3 img {
  max-width: 75%;
  padding: 0px 0px;
  height: auto;
}

.img-container4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
}

.img-container4 img {
  width: 30%;
  height: auto;
  padding: 0px 50px;
}
.img-container5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.img-container5 img {
  width: 100%;
  height: auto;
  padding: 0px 0px;
}
.img-container6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
}

.img-container6 img {
  width: 90%;
  height: auto;
  padding: 20px 0px;
}
.img-container7 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}

.img-container7 img {
  width: 100%;
  height: auto;
  padding: 0px 0px;
}

@media (max-width: 480px) {
  .gw-container,
  .gw-container2,
  .img-container2,
  .img-container3,
  .img-container5,
  .img-container6,
  .img-container7 {
    display: block;
  }
  .img-container2 {
    display: flex;
    justify-content: flex-start;
    margin-left: 125px;
  }
  .img-container3 {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
  }
  .img-container4 {
    display: flex;
    justify-content: center;
  }

  .gw-titulo,
  .gw-titulo2,
  .gw-titulo3,
  .gw-titulo4,
  .gw-titulo5,
  .gw-text,
  .gw-text2,
  .gw-text3 {
    font-size: 16px;
    margin: 10px 15px;
    text-align: left;

  }
  .gw-titulo6 {
    padding: 10px 15px;
    text-align: left;
    font-size: 19px;
    font-family: none;
  }

  .img-container6 img {
    width: 40%;
    height: auto;
    padding: 10px 0;
  }

  .img-container img {
    width: 70%;
    height: auto;
    padding: 10px 0;
  }

  .img-container2 img,
  .img-container3 img,
  .img-container5 img {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
  }
  .img-container6 img {
    width: 100%;
    height: auto;
    padding: 10px 0;
  }
  .img-container4 img {
    width: 35%;
    height: auto;
    padding: 0px 0px;
  }
}
