.space_right {
    padding: 20px 30px;
    background: #fff;
}

.space-container_right {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0px 0px;
    max-width: 1500px;
}

.space-image_right img{
    width: 100%;
}

.space-text_right {
    display: grid;
    place-items: center;
    margin-left: 0px;
    background: #fff;
    text-align: justify;

}

.space-text_right div {
    max-width: 800px;
}

.container-h3_right {    
    width: 97%;
    color: var(--color-verdeoscuro);
    padding-bottom: 15px;
    font-size: 20px;
    text-align: left;

}


.container-pgh_right {
    width: 97%;
    color: #000;
    font-weight: 300;
    font-size: 20px;
}
.container-pgh_right2 {
    width: 97%;
    color: #000;
    font-weight: 300;
    font-size: 20px;
    padding-top: 15px;
}
.custom-button {
    background-color: #ffffff; /* Green background color */
    border: 2px solid #9d9d9d; /* Border */
    color: white; /* White text color */
    padding: 15px 50px; /* Padding */
    font-weight: bold; /* Bold text */
    text-align: center; /* Centered text */
    text-decoration: none; /* No underline */
    display: inline-block; /* Inline block display */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor on hover */
    border-radius: 4px; /* Rounded corners */
  }
  
  .custom-button:hover {
    background-color: #b9beb9; /* Darker green background color on hover */
    border-color: #898b89; /* Darker border color on hover */
  }
  .custom-button span {
    color: #ff0000; /* Custom font color */
  }
  

@media (max-width: 768px) {
    .space_right {
        padding: 30px 10px;
    }

    .space-container_right {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .space-text_right {
        margin: 0;
    }

    .space-text_cont {
        margin-top: 15px;
    }

    .container-h3_right, .container-pgh_right {
        width: auto;
    }

    .container-h3_right {
        font-size: 30px;
    }

    .container-pgh_right {
        font-size: 20px;
        font-weight: 300;
        padding: 0 20px;
    }

    .container-hr_rigt {
        width: 100px;
        border: 3px solid var(--color-verdeoscuro);
        display: table-caption;
    }
}